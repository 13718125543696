import './footer.style.scss';
import { FaRegCopyright, FaGlobeAmericas } from 'react-icons/fa';
import { FaFacebookF } from 'react-icons/fa';
import { RxInstagramLogo } from 'react-icons/rx';
import React from 'react';
import config from '../../configs/config';

const footerLinks = [
  {
    id: 1,
    name: 'Facebook',
    icon: <FaFacebookF />,
    navigateUrl:
      'https://www.facebook.com/EncoraLLC/?view_public_for=101325278316451',
  },
  {
    id: 2,
    name: 'Instagram',
    icon: <RxInstagramLogo />,
    navigateUrl: 'https://www.instagram.com/encorainc/',
  },
  {
    id: 3,
    name: 'Linkdin',
    icon: <FaGlobeAmericas />,
    navigateUrl: 'https://www.linkedin.com/company/green-grubbox-llc/',
  },
];

const getYear = () => {
  return new Date().getFullYear();
};
export const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="footerSec">
              <div className="footerLink">
                <a
                  href={`${config.websiteLink}/terms-conditions/`}
                  className="terms text-decoration-none"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="copyIcon">
                    <FaRegCopyright />
                  </span>
                  <span className="ms-1 me-1">{getYear()}</span>
                  Encora,
                  <span className="ms-1">
                    LLC Terms of Use and Privacy Policy
                  </span>
                </a>
              </div>
              <div className="footerIcons">
                <div className="links">
                  {footerLinks.map((item: any) => {
                    return (
                      <React.Fragment key={item.id}>
                        <a
                          href={item.navigateUrl}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {item.icon}
                        </a>
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
