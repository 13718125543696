import React from 'react';
import { Modal } from 'react-bootstrap';
import './model.style.scss';
import StarRatingComponent from 'react-star-rating-component';
import { viewImageHandler } from '../handler';

interface Iprops {
  show: boolean;
  setShow: (show: boolean) => void;
  data: any;
}

const DashboardModel: React.FC<Iprops> = ({ show, setShow, data }) => {
  return (
    <>
      <Modal className="model" show={show} onHide={() => setShow(false)}>
        <Modal.Header>
          <div className="model-title">
            <img src={viewImageHandler(data)} alt="list" />
          </div>
        </Modal.Header>
        <Modal.Body>
          <p className="listName">{data.name}</p>
          <p className="listDetails">{data.category}</p>
          <StarRatingComponent
            name="rate2"
            editing={false}
            starCount={5}
            value={data.rating || 3}
          />
          <p className="addresss">{data.address.formattedAddress}</p>
          <p>{data?.description}</p>

          <a
            href={`http://maps.google.com/?q=${data.geo.coordinates[1]},${data.geo.coordinates[0]}`}
            target="_blank"
            rel="noreferrer"
            className="btn-link"
          >
            <button className="btn-info"> VIEW IN GOOGLE MAP</button>
          </a>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn-close" onClick={() => setShow(false)}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DashboardModel;
