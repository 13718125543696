import { useEffect } from 'react';
import './App.css';
import config from './configs/config';
import RoutePath from './routes/RoutePath';

function App() {
  useEffect(() => {
    const favicon: any = document.querySelector('link[rel="icon"]');
    favicon.href = config.faviconPath;
    window.document.title = config.projectTitle;
  }, []);

  return (
    <div className="App">
      <RoutePath />
    </div>
  );
}

export default App;
