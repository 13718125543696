import { Formik, Form, Field, ErrorMessage, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import './registration.style.scss';
import {
  AiOutlineUser,
  AiOutlineMail,
  AiOutlineMobile,
  AiOutlineLock,
} from 'react-icons/ai';

import { MdAddchart } from 'react-icons/md';

import { HttpCallGet, HttpCallPost } from '../../../services/NetworkCall';
import { ToastOnFailure, ToastOnSuccess } from '../../../toast/Toast';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../utils/Loader/Loader';
import { useEffect, useState } from 'react';
import {
  dataHandler,
  emailAddHandler,
  errorChecker,
  validateDomainRistriction,
  valueExtractor,
} from './handler';
import TermsAndConditionsModal from '../terms and condition/TermsModel';
import UsaFlag from '../../../assests/usaFlag.webp';
interface FormValues {
  name: string;
  email: string;
  mobile: string;
  password: string;
  locationPicker: string;
  isDomainRestriction: boolean;
  domain: any;
  domainPicker: string;
}

interface Iprops {
  activeState: boolean;
  setActiveState: any;
  setLoading: any;
}

const Registration: React.FC<Iprops> = ({
  activeState,
  setActiveState,
  setLoading,
}) => {
  const [selectedLocation, setSelectedLocation] = useState<any>({
    isDomainRestriction: false,
  });
  const navigate = useNavigate();
  const [locationData, setLocationData] = useState([]);
  const [extraFieldset, setExtraFieldset] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [show, setShow] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialValues: any = {
    name: '',
    email: '',
    mobile: '',
    password: '',
    locationPicker: '',
    domainPicker: '',
    isDomainRestriction: selectedLocation?.isDomainRestriction || false,
    domain: selectedLocation?.domain,
    additionalFields: {},
    termsAndCondition: false,
  };

  const registrationSchema = Yup.object().shape({
    name: Yup.string()
      .matches(/^(?! *$)[a-zA-Z\s]+$/, 'Name must be valid')
      .required('Name is required'),
    email:
      initialValues.isDomainRestriction === false
        ? Yup.string().email('Invalid email').required('Email is required')
        : Yup.string()
            .matches(/^[A-Za-z0-9.]+$/, 'Invalid input')
            .required('Email is required'),
    mobile: Yup.string()
      .matches(
        /^(?!0+$)\d{10}$/,
        'Number must be valid and must have 10 digits'
      )
      .required('Number is required'),

    password: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .max(16, 'Password must not be greater than 16 characters')
      .test(
        'no-spaces',
        'Password must not contain only spaces',
        (value: any) => {
          return !/^\s+$/.test(value);
        }
      )
      .required('Password is required'),

    locationPicker: Yup.string().required('Location is required'),
    domainPicker:
      initialValues.isDomainRestriction === true
        ? Yup.string().required('Domain is required')
        : Yup.string(),

    termsAndCondition:
      selectedLocation?.termsAndCondition?.length > 0
        ? Yup.boolean().oneOf(
            [true],
            'You must accept the terms and conditions'
          )
        : Yup.boolean(),
  });

  const handleSubmit = (
    values: FormValues,
    { resetForm }: FormikHelpers<FormValues>
  ) => {
    if (errorChecker(extraFieldset)) {
      console.log('Error Found');
      return;
    }
    registerUser(values)
      .then((res: any) => {
        if (res?.data?.isRegistered === false) {
          resetForm();
          sessionStorage.setItem('userEmail', res.data.email);
          sessionStorage.setItem('userId', res.data._id);
          navigate('/user-authentication');
        }
      })
      .catch((error) => {
        // Handle the registration error
        console.error(error);
      });
  };

  const registerUser = async (userData: any) => {
    try {
      setLoading(true);
      const finalEmail = selectedLocation?.isDomainRestriction
        ? emailAddHandler(userData.email, userData.domainPicker)
        : userData.email;
      sessionStorage.setItem('finalEmail', finalEmail);
      sessionStorage.setItem('phoneNumber', userData.mobile);
      console.log('userData.finalEmail', userData);
      const response: any = await HttpCallPost(`v1/user/signUp`, {
        email: finalEmail,
        password: userData.password,
        name: userData.name,
        contactNumber: userData.mobile,
        isDomainRestriction: selectedLocation.isDomainRestriction || false,
        locationId: userData.locationPicker,
        additionalFields:
          extraFieldset?.length > 0 ? valueExtractor(extraFieldset) : undefined,
      });
      setLoading(false);
      if (response?.data?.status === 1) {
        ToastOnSuccess(response.data.message);
        sessionStorage.setItem('user', JSON.stringify(response.data));
        sessionStorage.setItem(
          'locationId',
          response?.data?.data?.locationId[0]
        );

        return response.data;
      } else {
        ToastOnFailure(response.data.message);
        // setActiveState(!activeState);
        return response.data;
      }
    } catch (error) {
      setLoading(false);
      throw new Error(`Registration failed ${error}`);
    }
  };

  const getLocation = async () => {
    try {
      const response: any = await HttpCallGet(
        'admin/getLocationList?currentPage=1&pageSize=50'
      );
      setLocationData(response.data.data);
    } catch (error) {
      console.log('error', error);
    }
  };

  const fetchLocationById = async (id: any) => {
    if (id) {
      try {
        const response: any = await HttpCallGet(
          `admin/getLocationById?_id=${id}`
        );
        setSelectedLocation(response.data.data[0]);
        sessionStorage.setItem(
          'locationDetails',
          JSON.stringify(response.data.data[0])
        );
        if (response.data.data[0]?.extraFields?.length > 0) {
          setExtraFieldset(dataHandler(response.data.data[0].extraFields));
        }
      } catch (error) {
        console.log('error', error);
      }
    }
  };

  const onChangeHandler = (id: string, val: string) => {
    setExtraFieldset((items: any) => {
      const updatedItems = items.map((item: any) => {
        if (item.id === id) {
          const booleanValue = val.length > 0;
          return { ...item, value: val, error: !booleanValue, touched: true }; // Set the touched flag
        }
        return item;
      });
      return updatedItems;
    });
  };

  useEffect(() => {
    getLocation();
  }, []);

  return (
    <>
      <div className="registration-form">
        <Formik
          initialValues={initialValues}
          validationSchema={registrationSchema}
          onSubmit={handleSubmit}
          validateOnBlur={true}
        >
          {({ values, errors, touched, handleChange, setFieldValue }) => (
            <Form>
              <div className="regForm">
                <div className="form-group">
                  <div className="groupInput">
                    {/* <i className="fa fa-globe input-icon" /> */}
                    <Field
                      as="select"
                      name="locationPicker"
                      className={`form-select ${
                        errors.locationPicker && touched.locationPicker
                          ? 'is-invalid'
                          : ''
                      }`}
                      onChange={(e: any) => {
                        const { value } = e.target;
                        handleChange(e);
                        setFieldValue('locationPicker', value);
                        fetchLocationById(value);
                        setFieldValue('domainPicker', '');
                        setFieldValue('termsAndCondition', false);
                        setExtraFieldset([]);
                      }}
                    >
                      <option value="" label="Select" />
                      {locationData.map((item: any, index) => (
                        <option value={item._id} key={index}>
                          {item?.name}
                        </option>
                      ))}
                    </Field>
                    {errors.locationPicker && touched.locationPicker && (
                      <div className="error-message">
                        {errors.locationPicker}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <div className="groupInput">
                    <AiOutlineUser className="input-icon" />
                    <Field type="text" name="name" placeholder="Name" />
                  </div>
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="error-message"
                  />
                </div>

                {values?.domainPicker && (
                  <span className="select-domain">
                    Your email is:
                    <strong className="ms-1">
                      {emailAddHandler(values.email, values?.domainPicker)}
                    </strong>
                  </span>
                )}
                <div
                  className={`${
                    validateDomainRistriction(
                      selectedLocation?.isDomainRestriction
                    ) && `emailFrom`
                  } form-group `}
                >
                  <div className="groupInput">
                    <div
                      className={`${
                        validateDomainRistriction(
                          selectedLocation?.isDomainRestriction
                        ) && `domainAppend`
                      }`}
                    >
                      <div>
                        <AiOutlineMail className="input-icon regEmailIcon" />
                        <Field
                          className="regEmail"
                          type={`${
                            validateDomainRistriction(
                              selectedLocation?.isDomainRestriction
                            )
                              ? 'text'
                              : 'email'
                          }`}
                          name="email"
                          placeholder="Email"
                        />
                      </div>
                      <div>
                        {validateDomainRistriction(
                          selectedLocation?.isDomainRestriction
                        ) && (
                          <div className="form-groups">
                            <div className="groupInput">
                              {/* <i className="fa fa-globe input-icon" /> */}
                              {/* ${
                                  errors.domainPicker && touched.domainPicker
                                    ? 'is-invalid'
                                    : ''
                                }  */}
                              <Field
                                as="select"
                                name="domainPicker"
                                className={`form-select domainReg`}
                                onChange={(e: any) => {
                                  const { value } = e.target;
                                  handleChange(e);
                                  setFieldValue('domainPicker', value);
                                }}
                              >
                                <option value="" label="Domain" />
                                {selectedLocation?.domain?.map(
                                  (item: any, index: any) => (
                                    <option value={item} key={index}>
                                      {item}
                                    </option>
                                  )
                                )}
                              </Field>
                              {/* {errors.domainPicker && touched.domainPicker && (
                              // <div className="invalid-feedback">
                              //   {errors.domainPicker}
                              // </div>
                            )} */}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="error-emails">
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="error-message"
                    />
                    {selectedLocation?.isDomainRestriction && (
                      <ErrorMessage
                        name="domainPicker"
                        component="div"
                        className="error-message"
                      />
                    )}
                  </div>
                </div>

                <div className="form-group">
                  <div className="groupInput">
                    <div className="input-icon mobileInput">
                      <p>
                        <img
                          src={UsaFlag}
                          className="input-icon regMobile"
                          alt="usa Flag"
                        />
                      </p>
                      <p className="countryCode">+1</p>
                    </div>

                    <Field
                      className="devMobileInput"
                      type="text"
                      name="mobile"
                      placeholder="Mobile"
                    />
                  </div>
                  <ErrorMessage
                    name="mobile"
                    component="div"
                    className="error-message"
                  />
                </div>

                <div className="form-group">
                  <div className="groupInput">
                    <AiOutlineLock className="input-icon" />
                    <Field
                      type="password"
                      name="password"
                      placeholder="Password"
                    />
                  </div>
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="error-message"
                  />
                </div>
                {extraFieldset.length > 0 &&
                  extraFieldset.map((item: any) => (
                    <div key={item.id} className="form-group extraIcon">
                      <MdAddchart className="input-icon extraFieldIcons" />

                      <label htmlFor={item.id}>{item.name}</label>
                      <Field
                        type="text"
                        name={`additionalFields.${item.name}`}
                        className="form-control"
                        onBlur={() => {
                          onChangeHandler(item.id, item.value);
                        }}
                        value={item.value}
                        onChange={(e: any) => {
                          onChangeHandler(item.id, e.target.value);
                        }}
                      />
                      {(item.touched || formSubmitted) && item.error && (
                        <div className="error-emails">
                          <div className="error-message">
                            {/* {item.name}   */}
                            Field is required
                          </div>
                        </div>
                      )}
                    </div>
                  ))}

                {selectedLocation?.termsAndCondition?.length > 0 && (
                  <>
                    <div className="checkbox-group-terms">
                      <label>
                        <Field type="checkbox" name="termsAndCondition" />
                      </label>
                      <p className="infoText">
                        By creating an account, you agree to Encora{' '}
                        <span
                          className="linkText"
                          onClick={() => setShow(true)}
                        >
                          Conditions of Use
                        </span>{' '}
                        .
                      </p>
                    </div>
                    <ErrorMessage
                      name="termsAndCondition"
                      component="div"
                      className="error-message"
                    />
                  </>
                )}
              </div>
              <button
                type="submit"
                // disabled={isFormEmpty(values)}
                // className={`${
                //   isFormEmpty(values) ? `disableBtn` : `activeBtn`
                // }`}
                onClick={() => setFormSubmitted(true)}
                className="activeBtn"
              >
                Register
              </button>
              <p className="text-center signIn">
                Already have an account to{' '}
                {/* eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid */}
                <a
                  className="ms-2"
                  onClick={() => setActiveState(!activeState)}
                >
                  sign in?
                </a>
              </p>
            </Form>
          )}
        </Formik>
      </div>
      <TermsAndConditionsModal
        show={show}
        setShow={setShow}
        termsAndConditions={selectedLocation.termsAndCondition}
      />
    </>
  );
};

export default Registration;
