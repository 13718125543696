import React from 'react';
import './reward.styles.scss';
import { BsInfoCircleFill } from 'react-icons/bs';
import { AiOutlineClockCircle } from 'react-icons/ai';

interface Iprops {
  item: any;
}
const RewardListCard: React.FC<Iprops> = ({ item }) => {
  const rewardInfo = item ? item.rewardInfo[0] : '';

  const statusVarfied = (statusCode: number) => {
    const statusMap: { [key: number]: { Name: string; clsName: string } } = {
      1: { Name: 'Pending', clsName: 'pendingClr' },
      2: { Name: 'Claimed', clsName: 'claimedClr' },
      3: { Name: 'Donated', clsName: 'donatedClr' },
    };
    return statusMap[statusCode] || { Name: '', clsName: '' };
  };

  const usedBenefit = item.hasOwnProperty('usedBenefit')
    ? item.usedBenefit[0]
    : null;
  const rewardBenefitList = item.hasOwnProperty('rewardBenefitList')
    ? item.rewardBenefitList[0]
    : null;

  const benefitTitle = usedBenefit
    ? usedBenefit.benefitTitle || 'Benefit Title'
    : rewardBenefitList
    ? rewardBenefitList.benefitTitle || 'Benefit Title'
    : 'Benefit Title';
  const benefitDesc = usedBenefit
    ? usedBenefit.benefitDesc || 'Benefit Description'
    : rewardBenefitList
    ? rewardBenefitList.benefitDesc || 'Benefit Description'
    : 'Benefit Description';
  const points = usedBenefit
    ? usedBenefit.points || 0
    : rewardBenefitList
    ? rewardBenefitList.points || 0
    : 0;
  const benefitValue = usedBenefit
    ? usedBenefit.benefitValue || 'No costs'
    : rewardBenefitList
    ? rewardBenefitList.benefitValue || 'No costs'
    : 'No costs';

  return (
    <div className="container">
      <div className="rewardsListing">
        <div className="listing">
          <div className="container">
            <div className="row">
              <div className="col-md-3 col-lg-2 imageSize">
                <h2
                  className={`rewardList ${
                    item?.checkOutAway == 0
                      ? ''
                      : statusVarfied(item.status).clsName
                  }`}
                >
                  {item?.checkOutAway == 0
                    ? ''
                    : statusVarfied(item.status).Name}
                </h2>
                <img src={rewardInfo.image} alt="" />
              </div>
              <div className="col-md-9 col-lg-10">
                <div className="reward-benefit-column">
                  <div>
                    <div className="rewardInfoTitle">
                      <h5>{rewardInfo.title}</h5>
                    </div>
                    <div className="reward-benefit-data">
                      <div>
                        <h4>{benefitTitle}</h4>
                        <h4>{benefitDesc}</h4>
                        {/* <h6>{rewardInfo ? rewardInfo.description : ''}</h6> */}
                      </div>
                      <div className="pointsTitle">
                        {item?.checkOutAway > 0 && (
                          <div className="rewardBtn checkoutAway">
                            {item?.checkOutAway} Checkout Away
                          </div>
                        )}
                        <h5>{points} points</h5>
                        <h5> {benefitValue && `$ ${benefitValue}`}</h5>
                      </div>
                      <div className="rewardBtnDiv">
                        {/* $
                        {statusVarfied(item.status).clsName === 'pendingClr'
                          ? 'btnRed' ??
                            statusVarfied(item.status).clsName === 'donatedClr'
                            ? 'btnYellow'
                            : 'btnGreen'
                          : null} */}
                        {/* {`${
                          statusVarfied(item.status).clsName == 'pendingClr'
                            ? 'btnRed'
                            : statusVarfied(item.status).clsName == 'donatedClr'
                            ? 'btnYellow'
                            : 'btnGreen'
                        }`} */}
                        {/* {statusVarfied(item.status).Name} */}
                        {/* {item.checkOutAway} */}
                        {/* {item.checkOutAway} */}
                        {item?.checkOutAway == 0 ? (
                          <div className={`rewardBtn  ${'btnGreen'}`}>
                            <i className="fa fa-trophy"></i>
                            Claim Reward
                          </div>
                        ) : (
                          <div
                            className={`rewardBtn  ${
                              statusVarfied(item.status).clsName == 'pendingClr'
                                ? 'btnRed'
                                : statusVarfied(item.status).clsName ==
                                  'donatedClr'
                                ? 'btnYellow'
                                : 'btnGreen'
                            }`}
                          >
                            {statusVarfied(item.status).clsName ===
                            'pendingClr' ? (
                              <AiOutlineClockCircle />
                            ) : (
                              <BsInfoCircleFill />
                            )}

                            {statusVarfied(item.status).Name}
                          </div>
                        )}
                        {/* <div
                          className={`rewardBtn  ${
                            statusVarfied(item.status).clsName == 'pendingClr'
                              ? 'btnRed'
                              : statusVarfied(item.status).clsName ==
                                'donatedClr'
                              ? 'btnYellow'
                              : 'btnGreen'
                          }`}
                        >
                          {statusVarfied(item.status).clsName ===
                          'pendingClr' ? (
                            <AiOutlineClockCircle />
                          ) : (
                            <BsInfoCircleFill />
                          )}

                          {statusVarfied(item.status).Name}
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RewardListCard;
