import './Slider.style.scss';
import Carousel from 'react-multi-carousel';

interface Iprops {
  items: any;
  openModel: any;
}

const Card: React.FC<Iprops> = ({ items, openModel }) => {
  return (
    <div>
      <div className="box_listing_rewards">
        <div
          className="background-light-green-top"
          onClick={() => openModel(items, true)}
        >
          <img src={items?.image} className="img-rewards" />
        </div>
        <p className="ptage"> {items?.title}</p>
        <div className="background-light-green-bottom">
          <h5>{items?.rewardBenefitList[0]?.benefitValue}$</h5>
          <h6>{items?.rewardBenefitList[0]?.benefitTitle}</h6>
        </div>
      </div>
    </div>
  );
};

export default Card;
