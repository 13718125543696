export const typeHandler = (str: string) => {
  return str === 'both' ? 3 : str === 'stations' ? 2 : 1;
};

export const clsAppender = (state: string, cmpString: string) => {
  return state === cmpString ? `btnActive` : ``;
};

export const latAndLngExtractor = (lat: any, lng: any) => {
  return { lat: lat, lng: lng };
};

export const viewImageHandler = (item: any) => {
  let viewImage: string;
  if (item.type === 1) {
    if (
      item.images !== undefined &&
      item.images !== null &&
      item.images !== ''
    ) {
      viewImage = item.images;
    } else {
      viewImage = item.vendorIcon;
    }
  } else {
    if (
      item.images !== undefined &&
      item.images !== null &&
      item.images !== ''
    ) {
      viewImage = item.images;
    } else {
      viewImage = item.dropBoxIcon;
    }
    viewImage = item.dropBoxIcon;
  }
  return viewImage;
};

export const mapKeyHandler = (data: any) => {
  return data.length > 0
    ? data.map((item: any) => ({
        ...item,
        position: {
          lat: item.geo.coordinates[1],
          lng: item.geo.coordinates[0],
        },
      }))
    : [];
};
