import React, { useEffect, useState } from 'react';
import Card from '../../utils/slider/Card';
import Carousel from 'react-multi-carousel';
import axios from 'axios';
import RewardListCard from './cards/RewardListCard';
import RewardModel from './Models/firstmodel/RewardModel';
import Loader from '../../utils/Loader/Loader';
import NoData from '../../utils/Loader/NoData/NoData';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

function Rewards() {
  const [rewardsList, setRewardsList] = useState([]);
  const [userRewardsList, serUserRewardsList] = useState([]);
  const useDetails = sessionStorage.getItem('USER_DETAILS');
  const [modelShow, setModelShow] = useState(false);
  const [activeField, setActiveField] = useState<any>({
    btnActive: 'info',
    data: {},
    isDisabled: false,
  });
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getAllRewardsList();
    getUserRewardsList();
  }, [modelShow]);
  // user/getAllRewardsList?currentPage=1&pageSize=10
  const getAllRewardsList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}user/getAllRewardsList`,
        {
          headers: {
            Authorization: sessionStorage.getItem('AUTH_TOKEN'),
          },
          params: {
            currentPage: 1,
            pageSize: 100,
            locationId: sessionStorage.getItem('locationId'),
          },
        }
      );
      setRewardsList(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getUserRewardsList = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}user/getUserRewardsList`,
      {
        headers: {
          Authorization: sessionStorage.getItem('AUTH_TOKEN'),
        },
        params: {
          currentPage: 1,
          pageSize: 100,
          userId: JSON.parse(sessionStorage.getItem('USER_DETAILS') || '').data
            ?._id,
        },
      }
    );
    serUserRewardsList(response?.data?.data);
  };

  const openModel = async (modelItem: any, flag: boolean) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}user/getRewardInfo`,
        {
          headers: {
            Authorization: sessionStorage.getItem('AUTH_TOKEN'),
          },
          params: {
            rewardId: flag ? modelItem._id : modelItem?.rewardInfo[0]?._id,
            userRewardId: flag ? undefined : modelItem?._id,
            userId: JSON.parse(sessionStorage.getItem('USER_DETAILS') || '')
              .data._id,
          },
        }
      );
      setActiveField({
        ...activeField,
        isDisabled: flag,
        data: { ...response.data.data[0] },
      });
      setLoading(false);
      setModelShow(true);
    } catch (error) {
      setLoading(false);
      setModelShow(false);
      console.log(error);
    }
  };

  if (loading) {
    return <Loader startLoading={loading} />;
  }

  return (
    <>
      <div className="container">
        <div className="CarouselDiv mt-3">
          <Carousel
            ssr
            partialVisbile
            itemClass="image-item"
            responsive={responsive}
            transitionDuration={2000}
            arrows={false}
          >
            {rewardsList?.map((item: any) => {
              return (
                <div key={item._id}>
                  <Card items={item} openModel={openModel} />
                </div>
              );
            })}
          </Carousel>
        </div>
        <div className="rewardsListing">
          <div>
            <div className="rewardsListing_title">
              <p>CLAIMED REWARDS</p>
            </div>
            <div className="listingDiv">
              {userRewardsList?.length > 0 ? (
                userRewardsList?.map((item: any) => {
                  return (
                    <div key={item._id} onClick={() => openModel(item, false)}>
                      <RewardListCard item={item} />
                    </div>
                  );
                })
              ) : (
                <NoData content="No reward claimed yet" />
              )}
            </div>
          </div>
        </div>
      </div>
      {activeField.data?.rewardInfo && (
        <RewardModel
          show={modelShow}
          setShow={setModelShow}
          activeField={activeField}
          setActiveField={setActiveField}
        />
      )}
    </>
  );
}

export default Rewards;
