import './NoData.scss';
import Card from 'react-bootstrap/Card';
interface Iprops {
  content: string;
}
const NoData: React.FC<Iprops> = ({ content }) => {
  return (
    <>
      <div className="MainBody">
        <div className="body">
          <div className="Nodata">
            <div className="text-center">{content}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NoData;
