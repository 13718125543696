import React from 'react';
import { Footer } from '../components/footer/Footer';
import { Navbar } from '../components/navbar/Navbar';
import 'bootstrap/dist/css/bootstrap.css';
import AuthLayout from '../components/auth/AuthLayout';
import { useState } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import Otp from '../pages/otp/Otp';
import PublicRoute from './PublicRoute';
import ProtectedRoute from './ProtectedRoute';
import Dashboard from '../pages/dashboard/Dashboard';
import Rewards from '../pages/rewards/Rewards';
import ContainerHistory from '../pages/Container-History/ContainerHistory';
import Notification from '../pages/Notification/Notification';
import Accounts from '../pages/Accounts/Accounts';
import PaymentVerfication from '../pages/Payment-Verfication/PaymentVerfication';
function RoutePath() {
  const navigate = useNavigate();

  const [isVerified, setVerified] = useState(
    sessionStorage.getItem('AUTH_TOKEN') ? true : false
  );
  const setSigninStatus = (status: boolean) => {
    setVerified(status);
    if (status) {
      navigate('/customer-dashboard');
    }
  };

  return (
    <div>
      <Navbar isVerified={isVerified} setSigninStatus={setSigninStatus} />
      <Routes>
        <Route path="/" element={<Navigate to="/customer-dashboard" />} />
        <Route
          path="/user-authentication"
          element={
            <PublicRoute
              isVerified={isVerified}
              redirectPath="/customer-dashboard"
            >
              <Otp setSigninStatus={setSigninStatus} />
            </PublicRoute>
          }
        ></Route>
        <Route
          path="/individual"
          element={
            <PublicRoute
              isVerified={isVerified}
              redirectPath="/customer-dashboard"
            >
              <AuthLayout setSigninStatus={setSigninStatus} />
            </PublicRoute>
          }
        ></Route>

        <Route
          path="/customer-dashboard"
          element={
            <ProtectedRoute isVerified={isVerified} redirectPath="/individual">
              <Dashboard />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/rewards"
          element={
            <ProtectedRoute isVerified={isVerified} redirectPath="/individual">
              <Rewards />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/box-history"
          element={
            <ProtectedRoute isVerified={isVerified} redirectPath="/individual">
              <ContainerHistory />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/box-notification"
          element={
            <ProtectedRoute isVerified={isVerified} redirectPath="/individual">
              <Notification />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/account"
          element={
            <ProtectedRoute isVerified={isVerified} redirectPath="/individual">
              <Accounts setSigninStatus={setSigninStatus} />
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/manage-membership"
          element={
            <PublicRoute
              isVerified={isVerified}
              redirectPath="/customer-dashboard"
            >
              <PaymentVerfication setSigninStatus={setSigninStatus} />
            </PublicRoute>
          }
        ></Route>
      </Routes>
      <Footer />
    </div>
  );
}

export default RoutePath;
