import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import vendor from '../../../assests/images/vendor.png';
import dropBox from '../../../assests/images/dropBox.png';
import './map.styles.scss';
import { Key, useEffect, useState } from 'react';

interface Iprops {
  List: any;
  center: any;
  callBackHandler: any;
}

export const Map: React.FC<Iprops> = ({ List, center, callBackHandler }) => {
  const containerStyle = {
    width: '100%',
    height: '600px',
  };

  const [zoomin, setZoomIn] = useState<number>(5);
  const [showMarker, setShowMarker] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setShowMarker(true);
    }, 1000);
  }, []);

  return (
    <div className="CustomMap">
      <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_KEY || ''}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={zoomin}
          options={{
            disableDefaultUI: true, // This will remove the default UI controls
          }}
        >
          {List.map((marker: any) => {
            return (
              <div className="marker-wrapper" key={marker._id}>
                {showMarker && (
                  <Marker
                    key={marker._id}
                    position={marker.position}
                    icon={{
                      url: marker.type === 1 ? vendor : dropBox,
                      // width: 40,
                      // height: 40,
                      scaledSize: new window.google.maps.Size(40, 40), // Adjust the size
                      // size: new window.google.maps.Size(40, 40),
                    }}
                    // defaultOptions={{ iconStyle: markerIconStyle }}
                    onClick={() => callBackHandler(true, marker)}
                  />
                )}
              </div>
            );
          })}
          <div className="Main-div">
            <div>
              <button
                onClick={() => setZoomIn(zoomin + 1)}
                className="zoomIn-btn"
              >
                <i className="fa-solid fa-plus"></i>
              </button>
            </div>
            <div>
              <button
                onClick={() => setZoomIn(zoomin - 1)}
                className="zoomOut-btn"
              >
                <i className="fa-solid fa-minus"></i>
              </button>
            </div>
          </div>
        </GoogleMap>
      </LoadScript>
    </div>
  );
};
