import React, { useState, useRef } from 'react';
import QrScanner from 'react-qr-scanner';
import './qr.style.scss';

const Camera = (props: any) => {
  const qrScannerRef = useRef(null);

  return (
    <div className="qrCode">
      <QrScanner
        ref={qrScannerRef}
        delay={600}
        onError={props.onError}
        onScan={props.onScan}
      />
    </div>
  );
};
interface Iprops {
  showCamera: any;
  setShowCamera: any;
  fetchScannedBoxes: any;
  setFlag: any;
  setShow: any;
}

const RewardRQScanner: React.FC<Iprops> = ({
  showCamera,
  setShowCamera,
  fetchScannedBoxes,
  setFlag,
  setShow,
}) => {
  const [isApiCallInProgress, setApiCallInProgress] = useState(false);

  const apiCall = (data: any) => {
    if (data) {
      setApiCallInProgress(true);
      fetchScannedBoxes(data);

      // Simulate the API call with a delay
      setTimeout(() => {
        setApiCallInProgress(false);
      }, 1000);
    }
  };

  const handleScan = (data: any) => {
    setFlag(false);
    if (data && !isApiCallInProgress) {
      apiCall(data);
      setShow(false);
      setShowCamera(false);
    }
  };

  const handleError = (error: any) => {
    console.log(error);
    setFlag(true);
  };

  return (
    <div>
      {showCamera && <Camera onScan={handleScan} onError={handleError} />}
    </div>
  );
};

export default RewardRQScanner;
