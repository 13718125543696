import React, { useEffect, useState } from 'react';
import { HttpCallGet } from '../../services/NetworkCall';
import NoData from '../../utils/Loader/NoData/NoData';
import './Notification.style.scss';
import Loader from '../../utils/Loader/Loader';
import Pagination from 'react-js-pagination';
import { json } from 'stream/consumers';

interface Iprops {
  startLoading: boolean;
  clsName?: string;
}

function Notification() {
  const [loading, setLoading] = useState<boolean>(false);
  const [notification, setNotification] = useState<any>([]);
  const [total, setTotal] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);

  function handlePageSizeChange(e: React.ChangeEvent<HTMLSelectElement>) {
    setPageSize(Number(e.target.value));
  }
  const userId = JSON.parse(sessionStorage.getItem('USER_DETAILS') || '').data
    ._id;

  const fetchNoficationData = () => {
    setLoading(true);
    HttpCallGet(
      `user/notification?currentPage=${currentPage}&pageSize=${pageSize}&userId=${userId}`,
      sessionStorage.getItem('AUTH_TOKEN')
    )
      .then((res: any) => {
        setLoading(false);
        setTotal(res.data.totalNotification);
        setNotification(res.data.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message);
      });
  };

  useEffect(() => {
    fetchNoficationData();
  }, [currentPage, pageSize]);

  if (loading) {
    return <Loader startLoading={loading} />;
  }

  return (
    <>
      {notification.length > 0 ? (
        notification.map((item: any, i: number) => {
          return (
            <>
              <div className="container mt-3">
                <div key={i}>
                  <div className="p-3 mb-5 bg-white notificationDiv">
                    <div className="Nofication_message">{item.message}</div>
                  </div>
                </div>
              </div>
            </>
          );
        })
      ) : (
        <NoData content="No notification availabe" />
      )}
      <div className="table__pagination__container">
        {total > pageSize && (
          <Pagination
            activePage={Number(currentPage)}
            itemsCountPerPage={pageSize}
            totalItemsCount={total}
            onChange={(page) => setCurrentPage(Number(page))}
          />
        )}
        {notification.length > 0 && (
          <select
            className="pagination__select"
            value={pageSize}
            onChange={handlePageSizeChange}
          >
            {[10, 20, 30].map((size) => (
              <option key={size} value={size}>
                Show {size}
              </option>
            ))}
          </select>
        )}
      </div>
    </>
  );
}

export default Notification;
