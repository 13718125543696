import React from 'react';
import './loader.style.scss';
// function Loader(props: any) {
//   return props.startLoader ? (
//     <div className="loader-div">
//       <ClipLoader color="#01331c" size={75} />
//     </div>
//   ) : (
//     <></>
//   );
// }

// export default Loader;

interface Iprops {
  startLoading: boolean;
  clsName?: string;
}
const Loader: React.FC<Iprops> = ({ startLoading, clsName }) => {
  return (
    <div className={`container mt-5 ${clsName}`}>
      {startLoading && (
        <div className="p-3 jumbotron rounded">
          <span className="loader"></span>
        </div>
      )}
    </div>
  );
};

export default Loader;
