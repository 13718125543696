export const validateDomainRistriction = (data: boolean) => {
  return data === true;
};

export const uidGenerator = () => {
  return Date.now().toString(36) + Math.random().toString(36).substr(2);
};

export const isFormEmpty = (values: any) => {
  return Object.values(values).some((value: any) => {
    if (typeof value === 'string') {
      return value.trim() === '';
    }
    return false; // Return false for non-string values
  });
};

export const emailAddHandler = (str1: string, str2: string) => {
  return (str1 += `@` + str2);
};

export const valueExtractor = (data: any) => {
  return data.length > 0
    ? data.map((item: any) => ({ name: item.name, value: item.value }))
    : [];
};

export const dataHandler = (data: any) => {
  return data.length > 0
    ? data.map((item: any) => ({
        id: uidGenerator(),
        name: item,
        value: '',
        error: true,
      }))
    : [];
};
export const errorChecker = (data: any) => {
  return data.some((item: any) => item.error === true);
};
