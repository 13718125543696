import { Formik, Form, Field, ErrorMessage, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import './login.style.scss';
import { AiOutlineMail, AiOutlineLock } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { FcGoogle } from 'react-icons/fc';
import React, { useState } from 'react';
import { HttpCallPost } from '../../../services/NetworkCall';
import { ToastOnFailure, ToastOnSuccess } from '../../../toast/Toast';
import ForgetPassModel from '../../../models/forgetPass/ForgetPassModel';
import ReCAPTCHA from 'react-google-recaptcha';
import GoogleLogin from 'react-google-login';

interface FormValues {
  email: string;
  password: string;
  recaptchaToken?: string;
}

interface Iprops {
  activeState: boolean;
  setActiveState: any;
  setSigninStatus: any;
  setLoading: any;
}

const Login: React.FC<Iprops> = ({
  activeState,
  setActiveState,
  setSigninStatus,
  setLoading,
}) => {
  const loginSchema = Yup.object().shape({
    email: Yup.string(),
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .max(16, 'Password must not be greater 16 characters')
      .required('Password is required'),
  });

  const initialValues: FormValues = {
    email: '',
    password: '',
  };
  const [showModel, setShowModel] = useState(false);
  const navigate = useNavigate();
  const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false);

  const handleSubmit = async (
    values: FormValues,
    { resetForm }: FormikHelpers<FormValues>
  ) => {
    loginUser(values)
      .then((res) => {
        if (res?.data?.status === 1) {
          resetForm();
        } else if (res?.status === 0) {
          ToastOnFailure(res?.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const loginUser = async (userData: FormValues) => {
    try {
      setLoading(true);
      const response: any = await HttpCallPost(`v1/user/signIn`, {
        email: userData.email,
        password: userData.password,
      });
      setLoading(false);
      if (response.data.status === 1 && response?.data?.data?.isRegistered) {
        sessionStorage.setItem('USER_DETAILS', JSON.stringify(response.data));
        sessionStorage.setItem('AUTH_TOKEN', response.data.token);
        sessionStorage.setItem('finalEmail', response.data.data.email);
        sessionStorage.setItem(
          'locationId',
          response?.data?.data?.locationId[0]
        );
        setSigninStatus(true);
        ToastOnSuccess(response.data.message);
        return response.data;
      } else if (response.data.status === 2) {
        sessionStorage.setItem('customerCardKey', response.data.rsaPublicKey);
        sessionStorage.setItem('acccountType', response.data.data.accountType);
        sessionStorage.setItem('finalEmail', response.data.data.email);
        sessionStorage.setItem('userId', response.data.data._id);
        navigate('/manage-membership');
      } else if (response.data.status === 3 || response.data.status === 4) {
        sessionStorage.setItem('userEmail', response.data.data.email);
        sessionStorage.setItem('finalEmail', response.data.data.email);
        sessionStorage.setItem('userId', response.data.data._id);
        navigate('/user-authentication');
      }
      return response.data;
    } catch (error: any) {
      setLoading(false);
      ToastOnFailure(error.message);
    }
  };

  const isFormEmpty = (values: FormValues) => {
    if (values.password.length < 6) {
      return true;
    }
    return Object.values(values).some((value) => value.trim() === '');
  };

  const handleGoogleLoginSuccess = (response: any) => {
    /** this funcationality is remaining yet we'll fix it for now on local they didn't give access  */
    if (response !== undefined && response !== null) {
      HttpCallPost(`user/socialSignIn`, {
        email: response.profileObj.email,
        socialType: 'GOOGLE',
        socialId: response.googleId,
        isVerified: true,
        name: response.profileObj.name,
      })
        .then(function (response: any) {
          if (response.data.status === 1) {
            setTimeout(function () {
              sessionStorage.setItem(
                'customerCardKey',
                response.data.rsaPublicKey
              );
              sessionStorage.setItem('AUTH_TOKEN', response.data.token);
              sessionStorage.setItem('customerName', response.data.name);
              sessionStorage.setItem('customerEmail', response.data.email);
              sessionStorage.setItem(
                'customerIsRegistered',
                response.data.isRegistered
              );
              sessionStorage.setItem('userId', response.data._id);
              setSigninStatus(true);
            }, 10);
          } else if (response.data.status === 2) {
            sessionStorage.setItem(
              'customerCardKey',
              response.data.rsaPublicKey
            );
            sessionStorage.setItem('userId', response.data.response.data._id);
            navigate('/manage-membership');
          } else if (response.data.status === 3 || response.data.status === 4) {
            sessionStorage.setItem(
              'userEmail',
              response.data.response.data.email
            );
            sessionStorage.setItem('userId', response.data.response.data._id);
            navigate('/user-authentication');
          } else {
            console.log('dev');
          }
        })
        .catch(function (error) {
          console.log('dev', error);
        });
    }
  };

  const handleGoogleLoginFailure = (error: any) => {
    ToastOnFailure(error);
  };

  return (
    <>
      <div className="login-form">
        <Formik
          initialValues={initialValues}
          validationSchema={loginSchema}
          onSubmit={handleSubmit}
        >
          {({ values }) => (
            <Form>
              <div className="form-group">
                <div className="groupInput">
                  <AiOutlineMail className="input-icon" />
                  <Field type="email" name="email" placeholder="Email" />
                </div>
                <ErrorMessage
                  name="email"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="form-group">
                <div className="groupInput">
                  <AiOutlineLock className="input-icon" />
                  <Field
                    type="password"
                    name="password"
                    placeholder="Password"
                  />
                </div>
                <ErrorMessage
                  name="password"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="form-group">
                <ReCAPTCHA
                  sitekey={`${process.env.REACT_APP_RECAPTCHA_KEY}`}
                  onChange={() => setIsRecaptchaVerified(true)}
                  onExpired={() => setIsRecaptchaVerified(false)}
                />
              </div>

              <button
                type="submit"
                disabled={!isRecaptchaVerified || isFormEmpty(values)}
                className="submit-button"
              >
                Login
              </button>
            </Form>
          )}
        </Formik>
        <span className="forgetpass" onClick={() => setShowModel(true)}>
          Forget Your Password?
        </span>
        <div className="google-login">
          {/* <GoogleLogin
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}
            buttonText="LOG IN WITH GOOGLE"
            onSuccess={handleGoogleLoginSuccess}
            onFailure={handleGoogleLoginFailure}
            cookiePolicy="single_host_origin"
            className="google-btn"
            render={(renderProps: any) => (
              <button
                className="google-btn"
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
              >
                <FcGoogle />
                <span className="ms-2">LOG IN WITH GOOGLE</span>
              </button>
            )}
          /> */}
        </div>
        <p className="text-center or">------------or------------</p>
        <p className="mt-2 text-center signUp">
          Need to
          {/* eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid */}
          <a className="ms-2" onClick={() => setActiveState(!activeState)}>
            sign up?
          </a>
        </p>
      </div>
      {showModel && <ForgetPassModel show={showModel} setShow={setShowModel} />}
    </>
  );
};

export default Login;
