import React from 'react';
import { Modal } from 'react-bootstrap';
import '../../models/forgetPass/forget.styles.scss';
// import '../forgetPass/forget.styles.scss';
import { AiOutlineMail } from 'react-icons/ai';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { HttpCallPost } from '../../services/NetworkCall';
import { ToastOnFailure, ToastOnSuccess } from '../../toast/Toast';

interface Iprops {
  show: any;
  setShow: any;
}
// src / models / forgetPass / yaman
const ForgetPassModel: React.FC<Iprops> = ({ show, setShow }) => {
  const handleSubmit = async (values: any) => {
    try {
      const response: any = await HttpCallPost(`user/forgot-password`, {
        email: values.email,
      });
      if (response.data.status === 1) {
        ToastOnSuccess(response.data.message);
        setShow(false);
      } else {
        ToastOnFailure(response.data.message);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const validateEmail = (value: string) => {
    let error;
    if (!value) {
      error = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(value)) {
      error = 'Invalid email address';
    }
    return error;
  };

  return (
    <div className="text-center forgetPass">
      <Modal show={show} onHide={() => setShow(false)} className="forgetPass">
        <Modal.Header className="header" closeButton>
          <Modal.Title>Forget password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center emailTxt">
            <span className="AddressField">
              Please enter your Email Address
            </span>
            <div></div>
            <br />
            {/* <AiOutlineMail className="icon" /> */}
            <Formik initialValues={{ email: '' }} onSubmit={handleSubmit}>
              {({ isValid, dirty }) => (
                <Form>
                  <i className="fa fa-envelope" aria-hidden="true"></i>
                  <Field
                    className="inputText mb-2"
                    type="email"
                    name="email"
                    placeholder="Enter Email"
                    validate={validateEmail}
                    required
                  />
                  <ErrorMessage
                    className="error-message"
                    name="email"
                    component="div"
                  />
                  <br />
                  <button type="submit" disabled={!isValid || !dirty}>
                    Submit
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ForgetPassModel;
