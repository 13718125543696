import React, { useState } from 'react';
import './auth.style.scss';
import Login from './login/Login';
import Registration from './registration/Registration';
import Steps from '../../pages/steps/Steps';
import Loader from '../../utils/Loader/Loader';
interface Iprops {
  setSigninStatus: any;
}
const AuthLayout: React.FC<Iprops> = ({ setSigninStatus }) => {
  const [activeState, setActiveState] = useState(true);
  const [loading, setLoading] = useState(false);

  return (
    <div>
      {loading ? (
        <Loader startLoading={loading} />
      ) : (
        <>
          <div className="Auth">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-6 d-none d-md-block"></div>
                <div className="col-sm-12 col-md-12 col-lg-6">
                  <div className="headTitle">
                    <h1 className="pagehead_title ">Individual Portal</h1>
                  </div>
                  {activeState ? (
                    <Login
                      activeState={activeState}
                      setActiveState={setActiveState}
                      setSigninStatus={setSigninStatus}
                      setLoading={setLoading}
                    />
                  ) : (
                    <Registration
                      activeState={activeState}
                      setActiveState={setActiveState}
                      setLoading={setLoading}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <Steps />
        </>
      )}
    </div>
  );
};

export default AuthLayout;
