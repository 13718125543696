import { useState, useEffect, FormEvent } from 'react';
import { HttpCallGet } from '../../services/NetworkCall';
import './Container.styles.scss';
import Pagination from 'react-js-pagination';
import NoData from '../../utils/Loader/NoData/NoData';
import Loader from '../../utils/Loader/Loader';

const ContainerHistory = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [containerData, setContainerData] = useState<any>([]);
  const [total, setTotal] = useState<any>(0);

  const userId = JSON.parse(sessionStorage.getItem('USER_DETAILS') || '').data
    ._id;

  const fetchContainerHistoryData = () => {
    setLoading(true);
    HttpCallGet(
      `user/history?currentPage=${currentPage}&pageSize=${pageSize}&userId=${userId}`,
      sessionStorage.getItem('AUTH_TOKEN')
    )
      .then((res: any) => {
        setLoading(false);
        setContainerData(res.data.data);
        setTotal(res.data.total);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message);
      });
  };

  function handlePageSizeChange(e: React.ChangeEvent<HTMLSelectElement>) {
    setPageSize(Number(e.target.value));
    setCurrentPage(1);
  }

  useEffect(() => {
    fetchContainerHistoryData();
  }, [currentPage, pageSize]);

  const onChange = (page: number) => {
    setCurrentPage(page);
  };
  if (loading) {
    return <Loader startLoading={loading} />;
  }

  return (
    <>
      {containerData?.length > 0 ? (
        containerData.map((item: any, i: number) => {
          return (
            <>
              <div className="container mt-5">
                <div key={i}>
                  <div className="p-3 mb-5 bg-white historyDiv">
                    <div className="Main_Div">
                      <div className="Image_section">
                        <img
                          src={item.boxIcon}
                          alt="icon"
                          className="img-responsive"
                        />
                        <div className="Text">
                          <h4 className="ng-binding">{item.boxId}</h4>
                        </div>
                      </div>
                      <div className="Details">
                        <p className="checkOutTime">
                          {item?.returnInventory
                            ? item.inventoryTime
                            : item.userCheckOutTime}
                        </p>
                        <h5>{item.vendorName}</h5>
                        <p>
                          {item?.returnInventory
                            ? 'Returned to inventory'
                            : item.isInReturnStation
                            ? 'Thank you for returning your 𝐄𝐧𝐜𝐨𝐫𝐚, Your container(s) will come off of your account when our team empties this station. This can take up to five days.'
                            : 'Still with you,please return when the grubbing is done'}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })
      ) : (
        <NoData content="No container histroy available" />
      )}
      <div className="table__pagination__container">
        {total > pageSize && (
          <Pagination
            activePage={Number(currentPage)}
            itemsCountPerPage={pageSize}
            totalItemsCount={total}
            onChange={(page: number) => setCurrentPage(Number(page))}
          />
        )}
        {containerData?.length > 0 && (
          <select
            className="pagination__select"
            value={pageSize}
            onChange={handlePageSizeChange}
          >
            {[10, 20, 30].map((size) => (
              <option key={size} value={size}>
                Show {size}
              </option>
            ))}
          </select>
        )}
      </div>
    </>
  );
};
export default ContainerHistory;
