export const KeyExtractorHandler = (obj: any) => {
  return {
    rewardInfo: obj.rewardInfo,
    userBenefits: obj.userBenefits,
    userCheckOuts: obj.userCheckOuts,
    checkOutAway: obj.checkOutAway,
    totalcheckOutPoints: obj.totalcheckOutPoints,
    isDonationEnabled: obj.rewardInfo[0].isDonationEnabled,
    usedUserRewardInfo: obj.usedUserRewardInfo,
  };
};
