import React from 'react';
import { Modal } from 'react-bootstrap';
import { KeyExtractorHandler } from '../handler';
interface Iprops {
  show: any;
  setShow: any;
  data: any;
}
const SecondModel: React.FC<Iprops> = ({ show, setShow, data }) => {
  const { rewardInfo, usedUserRewardInfo } = KeyExtractorHandler(data);

  return (
    <div>
      <Modal show={show} onHide={() => setShow(false)} className="SecondModal">
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="confirmation-screen">
              {usedUserRewardInfo !== ''
                ? usedUserRewardInfo[0].status === 3
                  ? 'Donated Details'
                  : usedUserRewardInfo[0].status === 2
                  ? 'Claimed Details'
                  : 'Charities'
                : 'Confirmation Screen'}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="UniqueId">{usedUserRewardInfo[0].uniqueId}</h4>
          <p className="bold">Date & Time</p>

          {usedUserRewardInfo !== '' &&
            (usedUserRewardInfo[0].status === 2 ? (
              <div>
                <h5 className="bold">Vendor Details</h5>

                {usedUserRewardInfo[0].usedVendor &&
                  usedUserRewardInfo[0].usedVendor.map((item: any) => {
                    return (
                      <div key={item._id}>
                        <h6 className="light">{item.mobile}</h6>
                        <h6 className="light">{item.email}</h6>
                      </div>
                    );
                  })}
              </div>
            ) : usedUserRewardInfo[0].status === 3 ? (
              <div>
                <p className="bold">Charity Details</p>
                <h6 className="light">
                  {usedUserRewardInfo[0].usedCharity.length > 0
                    ? usedUserRewardInfo[0].usedCharity[0].name
                    : 'Name of charity'}
                </h6>
                <h6 className="light">
                  {usedUserRewardInfo[0].usedCharity.length > 0
                    ? usedUserRewardInfo[0].usedCharity[0].description
                    : 'Description of charity'}
                </h6>
              </div>
            ) : null)}
          <p className="bold">Reward Details</p>
          <h6 className="light">
            {rewardInfo.length > 0 ? rewardInfo[0].title : 'Title of reward'}
          </h6>
          <h6 className="light">
            {rewardInfo.length > 0
              ? rewardInfo[0].description
              : 'Description of reward'}
          </h6>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={() => setShow(false)}>Close</button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SecondModel;
