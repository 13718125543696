import config from '../../configs/config';
import './steps.style.scss';
function Steps() {
  return (
    <div className="Steps container">
      <div className="text-center stepSign">
        <h2>Sign up today</h2>
        <p>Three Easy Steps to Becoming a Member and Getting Your Grub On.</p>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-4 col-lg-4">
          <div className="row">
            <div className="col-12 text-center">
              <img
                src={config.loginScreenImagePath1}
                className="phoneIcon"
                alt="col"
              />
            </div>
            <div className="col-12">
              <h4 className="stepCount">Step 1</h4>
              <p className="text">{config.loginScreenImageDesc1}</p>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-4 col-lg-4">
          <div className="row">
            <div className="col-12 text-center">
              <img
                src={config.loginScreenImagePath2}
                className="phoneIcon "
                alt="col"
              />
            </div>
            <div className="col-12">
              <h4 className="stepCount">Step 2</h4>
              <p className="text">{config.loginScreenImageDesc2}</p>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-4 col-lg-4">
          <div className="row">
            <div className="col-12 text-center">
              <img
                src={config.loginScreenImagePath3}
                className="phoneIcon"
                alt="col"
              />
            </div>
            <div className="col-12">
              <h4 className="stepCount">Step 3</h4>
              <p className="text">{config.loginScreenImageDesc3}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Steps;
