import React, { useEffect, useState } from 'react';
import { HttpCallGet } from '../../services/NetworkCall';
import './Accounts.styles.scss';
import box_img from '../../assests/images/historybox.png';
import icon from '../../assests/images/refresh_icon.png';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Loader from '../../utils/Loader/Loader';
import logOutUser from '../../services/Modifiers';
interface Iprops {
  setSigninStatus: any;
}
export const Accounts: React.FC<Iprops> = ({ setSigninStatus }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [AccountsData, setAccountsData] = useState<any>([]);

  const userId = JSON.parse(sessionStorage.getItem('USER_DETAILS') || '{}').data
    ?._id;
  const fetchAccountsData = () => {
    setLoading(true);
    HttpCallGet(`user?id=${userId}`, sessionStorage.getItem('AUTH_TOKEN'))
      .then((res: any) => {
        setLoading(false);
        setAccountsData(res.data.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message);
      });
  };

  useEffect(() => {
    fetchAccountsData();
  }, []);
  if (loading) {
    return <Loader startLoading={loading} />;
  }
  return (
    <>
      <div className="MainSection">
        <div className="container">
          <div className="accountSec">
            <div className="Accounts_Section">
              <div className="row">
                <div className="col-md-4">
                  <div className="Image_Box">
                    <h2>{AccountsData?.currentBoxes}</h2>
                    <img src={box_img} alt="Not found" />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="box_text">
                    <p>{`You have ${AccountsData?.currentBoxes} boxes checked out, get to grubbing!`}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div
                    className="View_Container_History"
                    onClick={() => navigate('/box-history')}
                  >
                    <img src={icon} />
                    <h3>view Container history</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="History_box">
              <div className="History_head">
                <h2>Account</h2>
              </div>
              <div
                className="iconss"
                onClick={() => logOutUser(setSigninStatus)}
              >
                <div className="Demo">
                  <i className="fas fa-sign-out"></i>
                </div>
                <p className="logout">Log-Out</p>
              </div>
            </div>
            <div className="Details_User">
              <div className="userDetails">
                <div className="userName">
                  <i className="fa fa-user common"></i>
                  <h3 className="Safe">Name</h3>
                </div>
                <div className="Values">
                  <h5>{AccountsData?.name}</h5>
                </div>
              </div>
              <div className="userDetails">
                <div className="userName">
                  <i className="fa fa-envelope common"></i>
                  <h3 className="Safe">Email</h3>
                </div>
                <div className="Values">
                  <h5>{AccountsData?.email}</h5>
                </div>
              </div>
              <div className="userDetails">
                <div className="userName">
                  <i className="fa fa-user common"></i>
                  <h3 className="Safe">Account type</h3>
                </div>
                <div className="Values">
                  <h5>
                    {AccountsData?.accountType == 2
                      ? 'Corporate'
                      : 'Individual'}
                  </h5>
                </div>
              </div>
              <div className="userDetails">
                <div className="userName">
                  <i className="fa fa-cube common"></i>
                  <h3 className="Safe">Total boxes used</h3>
                </div>
                <div className="Values">
                  <h5>{AccountsData?.currentBoxes}</h5>
                </div>
              </div>
              <div className="userDetails">
                <div className="terms_Condition userName">
                  <i className="fa fa-snowflake common"></i>
                  <Link
                    target="_blank"
                    to="https://encora.co/terms-conditions/"
                    className="ReDireactLink"
                  >
                    <h3 className="Safe">Term and Condition</h3>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Accounts;
