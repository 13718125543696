import logo from '../assests/newlogo.png';
import loginScreenImage1 from '../assests/images/login1.png';
import loginScreenImage2 from '../assests/images/login2.jpeg';
import loginScreenImage3 from '../assests/images/login3.jpeg';
import favicon from '../assests/favicon.png';

const config = {
  websiteLink: 'https://encora.co',
  logoPath: logo,
  projectTitle: 'Encora::User',
  faviconPath: favicon,
  loginScreenImagePath1: loginScreenImage1,
  loginScreenImagePath2: loginScreenImage2,
  loginScreenImagePath3: loginScreenImage3,
  loginScreenImageDesc1:
    'Thanks for downloading the rTurn App. Please create your account.',
  loginScreenImageDesc2:
    'Use the app to find encora Vendors and scan the QR code on your container.',
  loginScreenImageDesc3:
    'Bring used containers to our self-serve return station and scan for immediate rewards (or continue accruing)',
  facebookRidrection:
    'https://www.facebook.com/EncoraLLC/?view_public_for=101325278316451',
  instagramRidrection: 'https://www.instagram.com/encorainc/',
  linkedinRedirection: 'https://www.linkedin.com/company/green-grubbox-llc/',
};

export default config;
