import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import RewardRQScanner from './RewardRQScanner';
interface Iprops {
  show: any;
  setShow: any;
  showCamera: any;
  setShowCamera: any;
  fetchScannedBoxes: any;
}
const ThirdModel: React.FC<Iprops> = ({
  show,
  setShow,
  showCamera,
  setShowCamera,
  fetchScannedBoxes,
}) => {
  const [flag, setFlag] = useState(false);
  return (
    <div>
      <Modal show={show} onHide={() => setShow(false)} className="SecondModal">
        <Modal.Header closeButton>
          <Modal.Title className="scanModelHeader">Scan QR Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!flag ? (
            <RewardRQScanner
              showCamera={showCamera}
              setShowCamera={setShowCamera}
              fetchScannedBoxes={fetchScannedBoxes}
              setFlag={setFlag}
              setShow={setShow}
              // show={show}
            />
          ) : (
            <div className="container">
              <p className="modelMessage">Please allow to access your camera</p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button onClick={() => setShow(false)}>Close</button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ThirdModel;
