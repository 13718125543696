import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './terms.style.scss'; // Import your SCSS file

interface TermsAndConditionsModalProps {
  show: boolean;
  setShow: (show: boolean) => void;
  termsAndConditions: string;
}

const TermsAndConditionsModal: React.FC<TermsAndConditionsModalProps> = ({
  show,
  setShow,
  termsAndConditions,
}) => {
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="termsPolicy">
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header className="termsPolicyHeader" closeButton>
          <Modal.Title className="modal-title">
            Terms and Conditions
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modalBody">
          <pre className="preContent">{termsAndConditions}</pre>
        </Modal.Body>
        <Modal.Footer className="termsPolicyFooter">
          <Button variant="secondary" onClick={handleClose} className="close">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TermsAndConditionsModal;
