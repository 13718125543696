import Modal from 'react-bootstrap/Modal';
import QRScanner from '../../qrscan/QrScan';
import { useState } from 'react';
import appleIcon from '../../../assests/images/icon_apple1.png';
import gogleIcon from '../../../assests/images/icon_google1.png';
interface Iprops {
  show: any;
  setShow: any;
  showCamera: any;
  setShowCamera: any;
  fetchScannedBoxes: any;
}
const NavbarModel: React.FC<Iprops> = ({
  show,
  setShow,
  showCamera,
  setShowCamera,
  fetchScannedBoxes,
}) => {
  const [flag, setFlag] = useState(false);
  return (
    <>
      <Modal
        show={show.showModel}
        onHide={() => setShow({ ...show, showModel: false })}
        className="modal-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title className="scanModelHeader">Scan QR Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!flag ? (
            <QRScanner
              showCamera={showCamera}
              setShowCamera={setShowCamera}
              fetchScannedBoxes={fetchScannedBoxes}
              setFlag={setFlag}
            />
          ) : (
            <div className="container">
              <p className="modelMessage">
                Please download the app to scan the box.
              </p>
              <div className="row">
                <div className="col-lg-6">
                  <div className="centerDiv">
                    <a
                      href="https://apps.apple.com/in/app/encora/id1507740332"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={appleIcon} alt="apple" />
                    </a>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="centerDiv">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.tv.Encora"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={gogleIcon} alt="apple" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NavbarModel;
