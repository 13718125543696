import React, { useState } from 'react';
import './otp.style.scss';
import { HttpCallPost } from '../../services/NetworkCall';
import { ToastOnFailure, ToastOnSuccess } from '../../toast/Toast';
import Loader from '../../utils/Loader/Loader';
import { useNavigate } from 'react-router-dom';
interface Iprops {
  setSigninStatus: any;
}
const Otp: React.FC<Iprops> = ({ setSigninStatus }) => {
  const [pin, setPin] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const data = JSON.parse(sessionStorage.getItem('locationDetails') || `{}`);
  const user = JSON.parse(sessionStorage.getItem('user') || '{}');

  const handleResendOtp = async () => {
    try {
      setLoading(true);
      const response: any = await HttpCallPost(`user/resendOTP`, {
        userDetail: user.optSendToNumber
          ? sessionStorage.getItem('phoneNumber')
          : sessionStorage.getItem('finalEmail'),
        isDomainRestriction: data.isDomainRestriction,
      });
      setLoading(false);
      if (response.data.status === 1) {
        ToastOnSuccess(response.data.message);
      } else if (response.data.status === 2) {
        ToastOnSuccess(response.data.message);
      } else {
        ToastOnFailure(response.data.message);
      }
    } catch (error: any) {
      setLoading(false);
      ToastOnFailure(error);
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const response: any = await HttpCallPost(`user/verifyOTP`, {
        userDetail: user.optSendToNumber
          ? sessionStorage.getItem('phoneNumber')
          : sessionStorage.getItem('finalEmail'),
        isCardRequired: data.isCardRequired,
        otp: pin,
      });
      setLoading(false);
      if (
        response.data.status === 1 &&
        response.data?.data?.isRegistered === true
      ) {
        ToastOnSuccess(response.data.message);
        sessionStorage.setItem('USER_DETAILS', JSON.stringify(response.data));
        sessionStorage.setItem('AUTH_TOKEN', response.data.token);
        sessionStorage.setItem('userId', response.data.data._id);
        sessionStorage.setItem(
          'locationId',
          response?.data?.data.locationId[0]
        );
        setSigninStatus(true);
      } else if (response.data?.data?.isRegistered === false) {
        sessionStorage.setItem('customerCardKey', response.data.rsaPublicKey);
        sessionStorage.setItem('acccountType', response.data.data.accountType);
        sessionStorage.setItem('USER_DETAILS', JSON.stringify(response.data));
        sessionStorage.setItem('userId', response.data.data._id);
        sessionStorage.setItem(
          'locationId',
          response?.data?.data.locationId[0]
        );
        navigate('/manage-membership');
      } else {
        ToastOnFailure(response.data.message);
      }
    } catch (error: any) {
      setLoading(false);
      ToastOnFailure(error);
    }
  };

  const handlePinChange = (e: any) => {
    setPin(e.target.value);
  };

  return (
    <>
      {loading ? (
        <Loader startLoading={loading} />
      ) : (
        <div className="Otp">
          <div className="otp-img"></div>
          <div className="otp-flex">
            <div className="otp-container">
              <div className="customermainbox">
                <h1>Verification Code</h1>
                <p>
                  Insert the 6 digit pin code sent to your{' '}
                  {user.optSendToNumber ? `Number` : `email`}.
                </p>
              </div>
              <input
                type="text"
                value={pin}
                onChange={handlePinChange}
                placeholder="Enter PIN"
                maxLength={6}
              />
              <span className="resendOtp" onClick={handleResendOtp}>
                Resend OTP
              </span>
              <button
                type="submit"
                className={`${pin.length <= 5 && `disabled`}`}
                onClick={handleSubmit}
                disabled={pin.length <= 5}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Otp;
