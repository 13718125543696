import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import './paymentVerfication.style.scss';
import { HttpCallPut } from '../../services/NetworkCall';
import { ToastOnFailure, ToastOnSuccess } from '../../toast/Toast';
import Loader from '../../utils/Loader/Loader';
import { useNavigate } from 'react-router-dom';

interface Iprops {
  setSigninStatus: any;
}

const PaymentVerfication: React.FC<Iprops> = ({ setSigninStatus }) => {
  const initialValues = {
    name: '',
    cardNumber: '',
    expiry: '',
    cvc: '',
  };
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    cardNumber: Yup.string()
      .required('Card number is required')
      .matches(/^\d{16}$/, 'Card number must be 16 digits long'),
    // .test(
    //   'is-positive',
    //   'Card number must be positive',
    //   (value) => parseInt(value) > 0
    // )
    // .test('no-alphabets', 'Card number cannot contain alphabets', (value) =>
    //   /^[0-9]+$/.test(value)
    // ),
    expiry: Yup.string()
      .required('Expiry is required')
      .matches(
        /^(0[1-9]|1[0-2])\/\d{2}$/,
        'Expiry date should be in MM/YY format'
      )
      .test('expiry', 'Expiry cannot be in the past', function (value) {
        if (!value) return false;

        const currentDate = new Date();
        const [month, year] = value.split('/').map(Number);
        const currentYear = currentDate.getFullYear() % 100;

        if (year < currentYear) {
          return false;
        } else if (year === currentYear && month < currentDate.getMonth() + 1) {
          return false;
        }

        return true;
      }),
    cvc: Yup.string()
      .required('CVC is required')
      .matches(/^[0-9]{3}$/, 'CVC must be 3 numeric digits')
      .test('cvc', 'CVC must be 3 digits long ', function (value) {
        if (!value) return false;
        return /^\d{3}$/.test(value);
      }),
    // cvc: Yup.string()
    //   .required('CVC is required')
    //   .matches(/^\d{3}$/, 'CVC must be 3 digits long'),
  });

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    try {
      setSubmitting(true);
      const response: any = await HttpCallPut(`v1/user/completeRegistration`, {
        userId: sessionStorage.getItem('userId') || '',
        accountType: sessionStorage.getItem('acccountType') || '',
        cardNumber: values.cardNumber,
        cardName: values.name,
        expMonth: values.expiry.split('/')[0],
        expYear: values.expiry.split('/')[1],
        cvc: values.cvc,
      });
      ToastOnSuccess(response.data.message);
      sessionStorage.setItem('AUTH_TOKEN', response.data.token);
      sessionStorage.setItem('customerName', response.data.data.name);
      sessionStorage.setItem('customerEmail', response.data.data.email);
      sessionStorage.setItem(
        'customerIsRegistered',
        response.data.data.isRegistered
      );
      sessionStorage.setItem('USER_DETAILS', JSON.stringify(response.data));
      sessionStorage.setItem('userId', response.data.data._id);
      sessionStorage.setItem('locationId', response?.data?.data?.locationId[0]);
      setSigninStatus(true);
      setSubmitting(false);
      navigate('/customer-dashboard');
    } catch (error: any) {
      setSubmitting(false);
      ToastOnFailure(error);
    } finally {
      setSubmitting(false);
    }
  };
  function handleInput(event: any) {
    const input = event.target;
    const value = input.value.replace(/ /g, ''); // Remove spaces
    const numericValue = value.replace(/\D/g, ''); // Remove non-numeric characters

    // Prevent 'e' and 'E'
    if (event.key === 'e' || event.key === 'E') {
      event.preventDefault();
      return;
    }

    // Update the input value with formatted card number
    // input.value = numericValue.replace(/(\d)(?=\d)/g);

    // Restrict negative values
    const floatValue = parseFloat(numericValue);
    if (isNaN(floatValue) || floatValue < 0) {
      input.value = ''; // Clear the input if negative value or NaN
    }
  }

  return (
    <>
      <div className="pVarification">
        <div className="verifyImg"></div>
        <div className="card-entry-form">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ handleSubmit, isSubmitting }) => (
              <form onSubmit={handleSubmit}>
                {isSubmitting ? (
                  <Loader startLoading={isSubmitting} />
                ) : (
                  <>
                    <div className="form-group">
                      <h5 className="header">Individual User</h5>
                      <label htmlFor="name">
                        Name
                        <span className="headingCard">
                          {' '}
                          (as it appears on your card){' '}
                        </span>
                      </label>
                      <Field
                        type="text"
                        placeholder="Name"
                        name="name"
                        id="name"
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="error-message"
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="cardNumber">Card Number</label>
                      <Field
                        type="text"
                        placeholder="0000 0000 0000 0000"
                        name="cardNumber"
                        id="cardNumber"
                        class="numericInput"
                        onInput={(event: any) => handleInput(event)}
                      />
                      <ErrorMessage
                        name="cardNumber"
                        component="div"
                        className="error-message"
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="expiry">Expiry</label>
                      <Field
                        type="text"
                        placeholder="MM/YY"
                        name="expiry"
                        id="expiry"
                      />
                      <ErrorMessage
                        name="expiry"
                        component="div"
                        className="error-message"
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="cvc">CVC</label>
                      <Field
                        type="text"
                        placeholder="123"
                        name="cvc"
                        id="cvc"
                        class="numericInput"
                        onInput={(event: any) => handleInput(event)}
                      />
                      <ErrorMessage
                        name="cvc"
                        component="div"
                        className="error-message"
                      />
                    </div>

                    <button type="submit" disabled={isSubmitting}>
                      Submit
                    </button>
                  </>
                )}
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default PaymentVerfication;
