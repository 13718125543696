import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import './rewardmodel.style.scss';
import { clsAppender } from '../../../dashboard/handler';
import logo from '../../../../assests/images/logo_crop75.png';
import SecondModel from '../secondmodel/SecondModel';
import { KeyExtractorHandler } from '../handler';
import ThirdModel from '../thirdmodel/ThirdModel';
import { ToastOnFailure, ToastOnSuccess } from '../../../../toast/Toast';
import { HttpCallPost } from '../../../../services/NetworkCall';
import defaultImage from '../../../../assests/default_user.png';

interface Iprops {
  show: boolean;
  setShow: (show: boolean) => void;
  activeField: any;
  setActiveField: any;
}

const RewardModel: React.FC<Iprops> = ({
  show,
  setShow,
  activeField,
  setActiveField,
}) => {
  const [modelShow, setModelShow] = useState(false);
  const [modelShowScan, setModelShowScan] = useState(false);
  const [showCamera, setShowCamera] = useState(false);

  const showDonatePopup = (item: any) => {
    setModelShow(true);
  };
  const showConfirmScreen = (item?: any) => {
    setModelShow(true);
  };
  const [loading, setLoading] = useState(false);
  const claimRewards = () => {
    setModelShowScan(true);
    setShowCamera(true);
  };

  const buttonOfBenfits = (benefit: any) => {
    const { usedUserRewardInfo, checkOutAway, isDonationEnabled } =
      KeyExtractorHandler(activeField.data);

    let donatedButtonDisable;
    let donatedButtonVisible;
    if (isDonationEnabled === true) {
      donatedButtonDisable = (
        <button
          type="button"
          className="btn btn-info btn-sm donate"
          data-toggle="modal"
          data-target="#orderpop"
          data-backdrop="static"
          data-keyboard="false"
          disabled
        >
          <i className="fa fa-dollar"></i> Donate
        </button>
      );

      donatedButtonVisible = (
        <button
          type="button"
          className="btn btn-info btn-sm donate"
          data-toggle="modal"
          data-target="#orderpop"
          data-backdrop="static"
          data-keyboard="false"
          onClick={() => showDonatePopup(benefit)}
        >
          <i className="fa fa-dollar"></i> Donate
        </button>
      );
    } else {
      donatedButtonVisible = <div></div>;
    }
    if (usedUserRewardInfo !== '' || usedUserRewardInfo) {
      if (usedUserRewardInfo && usedUserRewardInfo[0].status === 3) {
        return (
          <button
            type="button"
            className="btn btn-info btn-sm donate"
            onClick={() => showConfirmScreen()}
          >
            <i className="fa fa-info-circle"></i> Donated
          </button>
        );
      } else if (usedUserRewardInfo && usedUserRewardInfo[0].status === 2) {
        return (
          <button
            type="button"
            className="btn btn-info btn-sm claim"
            data-toggle="modal"
            data-target="#orderpop"
            data-backdrop="static"
            data-keyboard="false"
            onClick={() => showConfirmScreen()}
          >
            <i className="fa fa-info-circle"></i> Claimed
          </button>
        );
      } else if (usedUserRewardInfo && usedUserRewardInfo[0].status === 1) {
        if (checkOutAway === 0) {
          return (
            <div>
              {donatedButtonVisible}
              <button
                type="button"
                onClick={() => claimRewards()}
                className="btn btn-info btn-sm claim"
              >
                <i className="fa fa-trophy"></i> Claim reward
              </button>
            </div>
          );
        } else {
          return (
            <button
              type="button"
              className="btn btn-info btn-sm pending"
              data-toggle="modal"
              data-target="#orderpop"
              data-backdrop="static"
              data-keyboard="false"
            >
              <i className="fa fa-clock"></i> Pending
            </button>
          );
        }
      }
    } else {
      if (benefit?.status === 3) {
        return (
          <button
            type="button"
            className="btn btn-info btn-sm donate"
            data-toggle="modal"
            data-target="#orderpop"
            data-backdrop="static"
            data-keyboard="false"
            onClick={() => showConfirmScreen()}
          >
            <i className="fa fa-info-circle"></i> Donated
          </button>
        );
      } else if (benefit?.status === 2) {
        return (
          <button
            type="button"
            className="btn btn-info btn-sm claim"
            data-toggle="modal"
            data-target="#orderpop"
            data-backdrop="static"
            data-keyboard="false"
            onClick={() => showConfirmScreen()}
          >
            <i className="fa fa-info-circle"></i> Claimed
          </button>
        );
      } else if (benefit?.status == 1) {
        if (checkOutAway === 0) {
          return (
            <div>
              {donatedButtonVisible}
              <button
                type="button"
                className="btn btn-info btn-sm claim"
                data-toggle="modal"
                data-target="#orderpop"
                data-backdrop="static"
                data-keyboard="false"
              >
                <i className="fa fa-trophy"></i> Claim reward
              </button>
            </div>
          );
        } else {
          return (
            <div>
              {donatedButtonDisable}
              <button
                type="button"
                className="btn btn-info btn-sm claim"
                data-toggle="modal"
                data-target="#orderpop"
                data-backdrop="static"
                data-keyboard="false"
                disabled
              >
                <i className="fa fa-trophy"></i> Claim reward
              </button>
            </div>
          );
        }
      }
    }
  };

  /** this function is called for Claim rewards */

  const fetchScannedBoxes = async (data: any) => {
    try {
      setLoading(true);
      const response: any = await HttpCallPost(
        `user-reward/claim-checkOut`,
        {
          userId: JSON.parse(sessionStorage.getItem('USER_DETAILS') || '').data
            ._id,
          rewardQr: JSON.parse(data.text),
          benefitId: activeField.data.userBenefits[0]._id,
        },
        sessionStorage.getItem('AUTH_TOKEN')
      );
      if (response.data.status == 1) {
        ToastOnSuccess(response.data.message);
        setShow(false);
      } else {
        ToastOnFailure(response.data.message);
      }
    } catch (error) {
      // setState({
      //   ...state,
      //   showModel: false,
      // });
      setLoading(false);
      console.log('error', error);
    }
  };
  return (
    <>
      <Modal
        className="model Rewardmodel"
        show={show}
        onHide={() => setShow(false)}
      >
        <Modal.Header>
          <div className="model-title">
            <img className="logo" src={logo} alt="list" />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="tab-container">
            <button
              className={`${clsAppender(activeField.btnActive, 'info')}`}
              onClick={() => {
                setActiveField({ ...activeField, btnActive: 'info' });
              }}
            >
              Reward Info
            </button>
            <button
              className={`${clsAppender(activeField.btnActive, 'vendor')}`}
              onClick={() => {
                setActiveField({ ...activeField, btnActive: 'vendor' });
              }}
            >
              Associated Vendors
            </button>
          </div>

          {activeField.btnActive === 'info' ? (
            <>
              <div className="row">
                <div className="col-md-5 text-start">
                  <img
                    className="infoImg"
                    src={activeField?.data?.rewardInfo[0].image}
                    alt="infoImg"
                  />
                </div>
                <div className="col-md-7 CheckoutPointDiv">
                  <h2>{activeField?.data?.rewardInfo[0].title}</h2>
                  <span>{activeField?.data?.rewardInfo[0].description}</span>
                </div>
                <div className="CheckOutEachDiv">
                  <div>
                    <p className="point-circle">
                      <h6>{activeField?.data?.rewardInfo[0].rewardPoints}</h6>
                      Points in each Checkout
                    </p>
                  </div>
                  <div>
                    <p className="point-circle">
                      <h6>{activeField?.data?.checkOutAway}</h6>
                      Checkout Away
                    </p>
                  </div>
                </div>
              </div>
              <p className="BenefitTitle">Benefits</p>
              <hr />
              {activeField?.data.userBenefits.length > 0 &&
                activeField.data.userBenefits.map((benefit: any) => {
                  return (
                    <React.Fragment key={benefit._id}>
                      <div className="row">
                        <div className="col-sm-12 ">
                          <div className="BenefitContent">
                            <div className="benefit-boxes-design">
                              <p className="pointContent">
                                <p className="points">{benefit.points}</p>
                                <span>Points</span>
                              </p>
                            </div>
                            <div className="text-start">
                              <p>{benefit.benefitDesc}</p>
                              <p>$ {benefit.benefitValue}</p>
                              <div>
                                {buttonOfBenfits(benefit)}
                                {activeField.isDisabled && (
                                  <button
                                    className={`${
                                      activeField.isDisabled && `disableBtn`
                                    }`}
                                  >
                                    Claim Reward
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}
            </>
          ) : (
            <>
              {activeField.data?.rewardInfo[0].vendors.length > 0 &&
                activeField.data?.rewardInfo[0].vendors?.map((item: any) => {
                  return (
                    <div className="row m-4 vendorRow" key={item._id}>
                      <div className="col-3">
                        <img
                          className="vendor"
                          src={item?.images ? item?.images : defaultImage}
                          alt={item?.name}
                        />
                      </div>
                      <div className="col-9 text-start">{item.name}</div>
                    </div>
                  );
                })}
            </>
          )}
          <hr />
        </Modal.Body>
        <Modal.Footer>
          <button className="btn-close" onClick={() => setShow(false)}>
            Close
          </button>
        </Modal.Footer>
      </Modal>

      {activeField.data.usedUserRewardInfo && (
        <SecondModel
          show={modelShow}
          setShow={setModelShow}
          data={activeField.data}
        />
      )}
      {
        <ThirdModel
          show={modelShowScan}
          setShow={setModelShowScan}
          showCamera={showCamera}
          setShowCamera={setShowCamera}
          fetchScannedBoxes={fetchScannedBoxes}
        />
      }
    </>
  );
};

export default RewardModel;
