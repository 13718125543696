import { useEffect, useState } from 'react';
import './dashboard.styles.scss';
import { AiOutlineUnorderedList, AiOutlineSearch } from 'react-icons/ai';
import { BsMap } from 'react-icons/bs';
import axios from 'axios';
import {
  clsAppender,
  mapKeyHandler,
  typeHandler,
  viewImageHandler,
} from './handler';
import DashboardModel from './model/Model';
import { Map } from './map/Map';
import Loader from '../../utils/Loader/Loader';
import NoData from '../../utils/Loader/NoData/NoData';

function Dashboard() {
  const [state, setState] = useState({
    displayStyle: true,
    type: 'both',
    currentPage: 1,
    pageSize: 1000,
    search: '',
    lat: 47.605003,
    lng: -122.32940550000001,
    mapZoom: 5,
    draggable: true,
  });
  const [loading, setLoading] = useState(false);
  const [vendorList, setVendorList] = useState<any>([]);
  const [showModel, setShowModel] = useState(false);
  const [activeField, setActiveField] = useState<any>();
  const [total, setTotal] = useState<any>(0);

  const fetchVendorDetails = () => {
    // setLoading(true);
    axios
      .get(`${process.env.REACT_APP_BASE_URL}user/vendors`, {
        headers: {
          Authorization: sessionStorage.getItem('AUTH_TOKEN'),
        },
        params: {
          type: typeHandler(state.type),
          pageSize: state.pageSize,
          search: state.search,
          currentPage: state.currentPage,
          long: state.lng,
          lat: state.lat,
        },
      })
      .then((res: any) => {
        setLoading(false);
        setVendorList(res.data.data);
        setTotal(res.data.totalUsers);
      })
      .catch((err: any) => {
        setLoading(false);
        console.log('error', err);
      });
  };

  const openModel = (show: boolean, data: any) => {
    setShowModel(show);
    setActiveField(data);
  };

  const locationSuccess = (position: any) => {
    setState({
      ...state,
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    });
  };

  const locationFail = () => {
    console.log('could not get location ');
  };

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(locationSuccess, locationFail);
    } else {
      alert('Sorry, your browser does not support geolocation services.');
    }
  };
  const mapHandler = () => {
    setState({
      ...state,
      displayStyle: !state.displayStyle,
    });
    return;
  };

  useEffect(() => {
    getCurrentLocation();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchVendorDetails();
    }, 600);
    return () => {
      clearTimeout(timer);
    };
  }, [state.type, state.search, state.lat]);

  if (loading) {
    return <Loader startLoading={loading} />;
  }

  return (
    <div className="Dashboard">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-md-6 btn-container">
            <button
              className={`btn ${clsAppender(state.type, 'both')}`}
              onClick={() => {
                setState({
                  ...state,
                  type: 'both',
                });
              }}
            >
              BOTH
            </button>
            <button
              className={`btn ${clsAppender(state.type, 'vendor')}`}
              onClick={() => {
                setState({
                  ...state,
                  type: 'vendor',
                });
              }}
            >
              VENDORS
            </button>
            <button
              className={`btn ${clsAppender(state.type, 'stations')}`}
              onClick={() => {
                setState({
                  ...state,
                  type: 'stations',
                });
              }}
            >
              DROP STATIONS
            </button>
            <button
              className="btn state listIconButton"
              onClick={() => {
                mapHandler();
              }}
            >
              {state.displayStyle ? (
                <i className="fa fa-list-ul listIcon"></i>
              ) : (
                // <AiOutlineUnorderedList className="state-changer" />
                <>
                  <BsMap className="state-changer" />
                </>
              )}
            </button>
          </div>
          <div className="search-box col-sm-12 col-md-6 col-md-6 text-sm-start text-md-end text-lg-end mt-sm-0 mt-md-4 mb-3">
            <div className="mainDiv">
              <input
                className="search"
                type="text"
                placeholder="search..."
                onChange={(e: any) =>
                  setState({ ...state, search: e.target.value })
                }
              />
              <i className="fa fa-search search-icon" aria-hidden="true"></i>
            </div>
            <i className="fa-solid fa-location-dot icons"></i>
          </div>
        </div>
        {state.displayStyle && (
          <div className="map-container">
            <div className="map">
              <Map
                List={mapKeyHandler(vendorList)}
                center={{ lat: state.lat, lng: state.lng }}
                callBackHandler={openModel}
              />
            </div>
          </div>
        )}
        <div className="list-container">
          {!state.displayStyle && vendorList.length > 0 ? (
            vendorList.map((item: any) => {
              return (
                <div
                  key={item._id}
                  className="row listItem px-4 pt-3 pb-2"
                  onClick={() => openModel(true, item)}
                >
                  <div className="col-sm-12 col-md-1 text-center vendorImage">
                    <img
                      src={viewImageHandler(item)}
                      alt="list"
                      className="listImg"
                    />
                  </div>
                  <div className="col-sm-12 col-md-9 first-col">
                    <h1>{item.name}</h1>
                    <p className="listcategory">{item.category}</p>
                  </div>
                  <div className="col-sm-12 col-md-2 text-end">
                    {item.miles}
                  </div>
                </div>
              );
            })
          ) : (
            <div>
              {!state.displayStyle && <NoData content="No vendors found" />}
            </div>
          )}
        </div>
      </div>
      {showModel && (
        <DashboardModel
          show={showModel}
          setShow={setShowModel}
          data={activeField}
        />
      )}
    </div>
  );
}

export default Dashboard;
