import { ToastOnSuccess } from '../toast/Toast';
import { HttpCallGet } from './NetworkCall';
import Loader from '../utils/Loader/Loader';
export default function logoutUser(setSigninStatus: any) {
  HttpCallGet(`user/logout`, sessionStorage.getItem('AUTH_TOKEN'))
    .then((res: any) => {
      sessionStorage.clear();
      setSigninStatus(false);
      ToastOnSuccess(res.data.message);
    })
    .catch((err) => {
      console.log('error', err.message);
    });
}
