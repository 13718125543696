import './navbar.style.scss';
import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { HttpCallGet, HttpCallPost } from '../../services/NetworkCall';
import { ToastOnFailure, ToastOnSuccess } from '../../toast/Toast';
import axios from 'axios';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { confirmAlert } from 'react-confirm-alert';
import NavbarModel from './model/Model';
import Loader from '../../utils/Loader/Loader';
import logoutUser from '../../services/Modifiers';
import config from '../../configs/config';

const Navlinks = [
  {
    title: 'Dashboard',
    key: 1,
    url: '/customer-dashboard',
  },
  {
    title: 'Container History',
    key: 2,
    url: '/box-history',
  },
  {
    title: 'Scan',
    key: 3,
    url: '/scan',
  },
  {
    title: 'Notifications',
    key: 4,
    url: '/box-notification',
  },
  {
    title: 'Feeds',
    key: 5,
    url: '',
    link: 'https://encora.co/newsfeed/',
  },
  {
    title: 'Rewards',
    key: 6,
    url: '/rewards',
  },
  {
    title: 'Account',
    key: 7,
    url: '/account',
  },
  {
    title: 'Logout',
    key: 8,
    url: '',
  },
];
interface Iprops {
  isVerified: boolean;
  setSigninStatus: any;
}
export const Navbar: React.FC<Iprops> = ({ isVerified, setSigninStatus }) => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [showCamera, setShowCamera] = useState(false);

  const navigate = useNavigate();
  const [state, setState] = useState({
    pageSize: 10,
    currentPage: 1,
    userId: sessionStorage.getItem('USER_DETAILS')
      ? JSON.parse(sessionStorage.getItem('USER_DETAILS') || '').data._id
      : '',
    isUserRewardListExist: false,
    showModel: false,
  });

  const userRewardListCheck = () => {
    if (isVerified) {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}user/getUserRewardsList`, {
          headers: {
            Authorization: sessionStorage.getItem('AUTH_TOKEN'),
          },
          params: {
            pageSize: state.pageSize,
            currentPage: state.currentPage,
            userId: state.userId,
          },
        })
        .then((res: any) => {
          if (res.data.data.length > 0) {
            setState({ ...state, isUserRewardListExist: true });
          }
        })
        .catch((err: any) => {
          setLoading(false);
          console.log('error', err);
        });
    }
  };
  const IsScan = () => {
    confirmAlert({
      message: 'Do you want to claim that rewards?',
      buttons: [
        {
          label: 'No',
          onClick: () => {
            setState({ ...state, showModel: true });
            setShowCamera(true);
          },
        },
        {
          label: 'Yes',
          onClick: () => navigate('/rewards'),
        },
      ],
    });
  };

  const fetchScannedBoxes = async (data: any) => {
    try {
      setLoading(true);
      const response: any = await HttpCallPost(
        `user/box-checkOut`,
        {
          boxId: data.text,
          userId: JSON.parse(sessionStorage.getItem('USER_DETAILS') || '').data
            ._id,
        },
        sessionStorage.getItem('AUTH_TOKEN')
      );
      if (response.data.status === 1) {
        ToastOnSuccess(response.data.message);
        setLoading(false);
        setState({
          ...state,
          showModel: false,
        });
        setShowCamera(false);
        navigate('/box-history');
      } else {
        setState({
          ...state,
          showModel: false,
        });
        setLoading(false);
        confirmAlert({
          message: response.data.message,
          buttons: [
            {
              label: 'Try Again',
              onClick: () => {
                setState({ ...state, showModel: true });
                setShowCamera(true);
              },
            },
            {
              label: 'CANCEL',
              onClick: () => {
                navigate('/customer-dashboard');
                window.location.reload();
              },
            },
          ],
        });
        ToastOnFailure(response.data.message);
      }
    } catch (error) {
      setState({
        ...state,
        showModel: false,
      });
      setLoading(false);
      console.log('error', error);
    }
  };

  useEffect(() => {
    userRewardListCheck();
  }, []);

  if (loading) {
    return <Loader startLoading={loading} />;
  }

  return (
    <>
      {/* <NotificationPermission /> */}
      <div className="navbar">
        <nav className="container navbar navbar-expand-lg bg-body-tertiary">
          <div className="container-fluid navDiv">
            <NavLink className="navbar-brand navbarLink" to="/">
              <img src={config.logoPath} className="logo" alt="logo" />
            </NavLink>
            {isVerified && (
              <>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div
                  className="collapse navbar-collapse "
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav  mb-2 mb-lg-0 ms-auto">
                    <NavLink to={'/customer-dashboard'} className={`nav-link`}>
                      Dashboard
                    </NavLink>
                    <NavLink to={'/box-history'} className={`nav-link`}>
                      Container History
                    </NavLink>
                    <button
                      onClick={() => IsScan()}
                      className="nav-link navButton"
                    >
                      Scan
                    </button>
                    <NavLink to={'/box-notification'} className={`nav-link`}>
                      Notifications
                    </NavLink>

                    <a
                      className={`nav-link feeds`}
                      target="_blank"
                      href="https://encora.co/newsfeed/"
                    >
                      <p className="feedsColor">Feeds</p>
                    </a>
                    <NavLink to={'/rewards'} className={`nav-link`}>
                      Rewards
                    </NavLink>
                    <NavLink to={'/account'} className={`nav-link`}>
                      Account
                    </NavLink>

                    <button
                      onClick={() => logoutUser(setSigninStatus)}
                      className="nav-link navButton"
                    >
                      Logout
                    </button>
                  </ul>
                </div>
              </>
            )}
          </div>
        </nav>
      </div>
      <NavbarModel
        show={state}
        setShow={setState}
        showCamera={showCamera}
        setShowCamera={setShowCamera}
        fetchScannedBoxes={fetchScannedBoxes}
      />
    </>
  );
};
